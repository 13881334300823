import React, { useState } from 'react';

import {
  Typography,
  Box,
  CircularProgress,
  Container,
} from '@material-ui/core/';

import { useIntl } from 'react-intl';

import BaseHOC from '../../../../../components/BaseHOC';
import PrimaryButton from '../../../../../components/PrimaryButton';
import SecondaryButton from '../../../../../components/SecondaryButton';
import TextField from '../../../../../components/TextField';
import CancelSuccess from '../../../../../components/CancelSuccess';

import { cancelBookingCustomer } from '../../../../../actions';

const CancelBooking = ({ location, dispatch, enqueueSnackbar }) => {
  const urlParams = new URLSearchParams(location.search);
  const token = urlParams.get('token');
  const shopId = urlParams.get('shopId');
  const [cancelReason, setCancelReason] = useState('');
  const [successfulCancel, setSuccessfulCancel] = useState(false);
  const [successfulCancelDialog, setSuccessfulCancelDialog] = useState(false);
  const [loader, setLoader] = useState(false);

  const { formatMessage: f } = useIntl();

  return (
    <>
      <Container maxWidth="md">
        <Box
          display="flex"
          mt={4}
          mx="auto"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="h4" align="center">
            {successfulCancel
              ? f({ id: 'pageReviewThankyou' })
              : f({ id: 'pageCancelBookingTitle' })}
          </Typography>
        </Box>
        {successfulCancel ? null : (
          <>
            <Box
              display="flex"
              mt={5}
              mx="auto"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <TextField
                id="cancelReason"
                name="cancelReason"
                variant="outlined"
                multiline
                rows="6"
                label={f({ id: 'comment' })}
                fullWidth
                value={cancelReason}
                disabled={loader}
                onChange={(event) => setCancelReason(event.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>

            <Box
              display="flex"
              mt={4}
              mx="auto"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              {loader ? (
                <CircularProgress />
              ) : (
                <>
                  <Box>
                    <PrimaryButton
                      onClick={() => {
                        dispatch(
                          cancelBookingCustomer(
                            shopId,
                            token,
                            cancelReason,
                            enqueueSnackbar,
                          ),
                        )
                          .then((x) => {
                            setLoader(false);
                            setSuccessfulCancelDialog(true);
                            setSuccessfulCancel(true);
                          })
                          .catch((error) => {
                            setLoader(false);
                          });
                        setLoader(true);
                      }}
                    >
                      {f({ id: 'pageCancelBookingCancelBooking' })}
                    </PrimaryButton>
                  </Box>
                </>
              )}
            </Box>
          </>
        )}
      </Container>
      <CancelSuccess
        open={successfulCancelDialog}
        onClick={() => setSuccessfulCancelDialog(false)}
      />
    </>
  );
};

export default BaseHOC(CancelBooking);
