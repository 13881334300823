import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';

import SecondaryButton from './SecondaryButton';

const CancelSuccess = ({ onClick, open }) => {
  return (
    <Dialog disableEscapeKeyDown open={open}>
      <DialogTitle>
        <Box textAlign="center">
          <FormattedMessage id="bookingCancelSuccessDialogTitle" />
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box textAlign="center">
          <FormattedMessage
            id="bookingCancelSuccessDialogContent"
            values={{
              DialogHeading: (...chunks) => (
                <Typography variant="h6">{chunks}</Typography>
              ),
              DialogText: (...chunks) => (
                <Typography variant="body1">{chunks}</Typography>
              ),
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Box justifyContent="center" display="flex" flex={1}>
          <SecondaryButton variant="outlined" onClick={onClick}>
            <FormattedMessage id="buttonOk" />
          </SecondaryButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default CancelSuccess;
