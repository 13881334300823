import React from 'react';

import { IntlProvider } from 'react-intl';
import { isSalonzone } from '../utils';
import messages from '../data/messages/xx';
import salonzoneMessages from '../data/messages/salonzone/xx';

import CancelBooking from '../marketing/landing/components/common/pages/cancel-booking';
import FontSelector from '../components/FontSelector';

const Page = ({ location }) => (
  <IntlProvider
    locale="xx"
    messages={isSalonzone ? salonzoneMessages : messages}
  >
    <FontSelector>
      <CancelBooking location={location} />
    </FontSelector>
  </IntlProvider>
);

export default Page;
